/* Estilo de tabla de notificaciones */
.custom-dialog-form .p-dialog-content{
	overflow-y: hidden !important;
	padding: 0.1rem;
	align-content: center;
}
.custom-dialog-form .p-dialog-header{
	padding: 5px 5px 0 5px !important;
}
.custom-dialog-form .p-dialog-header .p-dialog-title {
    font-weight: 600;
    font-size: 0.85rem;
    padding: 0.2rem;
}
.p-datatable .p-datatable-thead > tr > th {
	padding: 0.2rem;
	font-size: 0.8rem;
}
.p-datatable .p-datatable-tbody>tr>td {
    padding: 0.2rem;
	font-size: 0.8rem;
	user-select: text !important;
    -webkit-user-drag: none;
}
.button--row--container {
	padding: 0 !important;
	margin: 0px !important;
	width: 40px !important;
  }

p-dialog .p-dialog-content:last-of-type {
    padding: 0.5rem !important;
}

.expanded-row-content {
    padding: 10px;
    transition: background-color 0.3s ease-in-out;
}

.highlighted-row {
    background-color: #c8e6c9 !important; /* Verde claro - Autorizado */
}

.highlighted-row-rej {
    background-color: #ffcdd2 !important; /* Rojo claro - Rechazado*/
}

/* Diaglogo de motivo de rechazo*/
.p-dialog .p-dialog-footer {
	padding: 0.5rem 1.5rem 0rem 1.5rem;
}

.p-dialog .p-dialog-header .p-dialog-header-icon {
    width: 1.5rem!important;
    height: 1.5rem!important;
}

/* Columnas de importes alineadas a la derecha */
.p-column-right-aligned {
    text-align: right;
    padding-right: 1rem;
    display: block;
    user-select: text !important;
    -webkit-user-drag: none;
}

.p-column-header-center {
    text-align: center !important;
}